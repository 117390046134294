/* .swal2-select {
    padding: 10px;
    margin: 10px 0;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.swal2-select:invalid {
    border-color: red;
} */

.select-styled {
    padding: 0;
    margin: 20.25px 40.5px 3px;
    width: 261.46px !important;
    height: 51.14px !important;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    font-size: 16px;
    /* Adjust font size as needed */
    /* Adjust the height as needed */
    line-height: 1.5;
}

.select-styled:focus {
    border-color: #007bff;
    /* Change the border color on focus */
}